import { Library } from '@googlemaps/js-api-loader'

export const libraries: Library[] = ['places', 'drawing']

export const defaultCenter = {
  // Set default center for Denmark
  lat: 56.1629,
  lng: 10.2039,
}

export const containerStyle = {
  width: '100%',
  height: '400px',
}

export const polygonOptions = {
  fillOpacity: 0.3,
  fillColor: '#00824B',
  strokeColor: '#005B38',
  strokeWeight: 2,
}
