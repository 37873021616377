import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import merge from 'lodash/merge'
import { z } from 'zod'

import { rentalAgreementsApi } from '@services/dashboard/entities/rental-agreement/rental-agreement.api'

import { useAppSelector } from '@store/hooks'
import type { RootState } from '@store/index'

const searchSchema = z.object({
  searchQuery: z.string().optional(),
  status: z.string().optional().nullable(),
  notificationStatus: z.string().optional().nullable(),
  startDate: z.string().optional().nullable(),
  projectId: z.number().optional().nullable(),
  owner: z
    .union([z.literal('kundeportal'), z.literal('gsv')])
    .optional()
    .nullable(),
})

export type SearchValues = z.infer<typeof searchSchema>

export type Owner = SearchValues['owner']

export type ListTypes = 'active' | 'inactive'

interface State {
  activeList: ListTypes
  currentPage: number
  lastPage?: number
  perPage: number
  filtersIsOpen: boolean
  loading: 'searching' | 'idle'
  search: SearchValues
}

const initialState: State = {
  activeList: 'active',
  currentPage: 1,
  lastPage: undefined,
  perPage: 10,
  filtersIsOpen: false,
  loading: 'idle',
  search: {},
}

const rentalAgreementsSlice = createSlice({
  name: 'rentalAgreements',
  initialState,
  reducers: {
    /**
     * Set the active list to either `active` or `inactive` rental agreements.
     */
    setActiveList: (state, { payload }: PayloadAction<ListTypes>) => {
      state.activeList = payload
      state.currentPage = initialState.currentPage
    },

    /**
     * Open filters and reset current page to 1.
     */
    openFilters: (state) => {
      state.filtersIsOpen = true
      state.currentPage = initialState.currentPage
    },

    /**
     * Close filters and reset the current page to 1.
     */
    closeFilters: (state) => {
      state.filtersIsOpen = false
      state.currentPage = initialState.currentPage
    },

    reset: () => initialState,

    /**
     * Increment the current page on the overview.
     */
    incrementPage: (state) => {
      state.currentPage += 1
    },

    /**
     * Decrement the current page on the overview.
     */
    decrementPage: (state) => {
      state.currentPage -= 1
    },

    /**
     * Set the per page value on the overview.
     */
    setPerPage: (state, { payload }: PayloadAction<State['perPage']>) => {
      state.perPage = payload
      state.currentPage = 1
    },

    /**
     * Merge the search values into the state.
     */
    setSearch: (state, { payload }: PayloadAction<SearchValues>) => {
      state.search = merge(state.search, payload)
      state.currentPage = initialState.currentPage
    },
  },

  extraReducers: (builder) => {
    builder
      /**
       * If the current page number (pagination) and the pending request (prefetching)
       * page argument matches, set searching loader
       *
       * This is done because a search loader would be visible when prefetching
       * the next page (pagination), which shouldn't be visible to the user.
       *
       * @see {getRentedEquipment} endpoint for the page argument
       * @see {SearchOverview} component for the search loader
       */
      .addMatcher(rentalAgreementsApi.endpoints.getRentalAgreements.matchPending, (state, { meta }) => {
        if (state.currentPage === meta.arg.originalArgs?.page) {
          state.loading = 'searching'
        }
      })
      /**
       * Add lastPage meta to state when the {@link getRentedEquipment} query fulfills
       */
      .addMatcher(rentalAgreementsApi.endpoints.getRentalAgreements.matchFulfilled, (state, { payload }) => {
        state.lastPage = payload.meta?.last_page ?? 1
        state.loading = 'idle'
      })
  },
})

// Actions
export const { setActiveList, openFilters, closeFilters, reset, setSearch, decrementPage, incrementPage, setPerPage } =
  rentalAgreementsSlice.actions

/**
 * Hook for selecting the Rental Agreements state
 * @returns The Rental Agreements state
 */
export const useRentalAgreementsState = (): State => {
  return useAppSelector((state: RootState) => state.rentalAgreements)
}

export default rentalAgreementsSlice.reducer
