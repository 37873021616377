import { ReactNode, createContext } from 'react'

import { useJsApiLoader } from '@react-google-maps/api'

import { libraries } from '@features/projects/utils/geofenceConstants'

import { env } from '@config'

export const GoogleMapsContext = createContext<{ isLoaded: boolean; loadError: Error | undefined }>({
  isLoaded: false,
  loadError: undefined,
})

export const GoogleMapsProvider = ({ children }: { children: ReactNode }) => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: env.GOOGLE_MAPS_KEY,
    libraries,
  })

  return <GoogleMapsContext.Provider value={{ isLoaded, loadError }}>{children}</GoogleMapsContext.Provider>
}
