import React, { ReactNode } from 'react'

import { GoogleMapsProvider } from 'contexts'
import { I18nextProvider } from 'react-i18next'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'

import { Auth0ProviderWithHistory } from '@features/auth/components/Auth0ProviderWithHistory'

import { persistor, store } from '@store/index'

import i18n from '@i18n'

import { FullPageLoader } from '@components/ui'

export const AppProviders = ({ children }: { children: ReactNode }) => {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={<FullPageLoader />} persistor={persistor}>
          <I18nextProvider i18n={i18n}>
            <GoogleMapsProvider>
              <Router>
                <Auth0ProviderWithHistory>{children}</Auth0ProviderWithHistory>
              </Router>
            </GoogleMapsProvider>
          </I18nextProvider>
        </PersistGate>
      </Provider>
    </React.StrictMode>
  )
}
